import * as React from 'react'

export default function Spinner({
  reverse = false,
  size = 'large',
}: {
  reverse?: boolean
  size?: 'small' | 'large'
}) {
  return (
    <svg
      // className="animate-spin w-8"
      className={`animate-spin ${size === 'small' ? 'h-4' : 'h-8'} ${
        size === 'small' ? 'w-4' : 'w-8'
      } ${reverse ? 'text-white' : 'text-red-500'}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <circle
        strokeWidth="4"
        className="opacity-25"
        stroke="currentColor"
        cx="12"
        cy="12"
        r="10"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}
