import * as React from 'react'

import { infoEmailAddress } from '../config'

export default function PedingReviewStep() {
  return (
    <div>
      <h1 className="mb-4 text-3xl">Thanks!</h1>
      <p className="text-gray-600">
        Your documentation is under review. We will let you know if everything
        is ok.
      </p>
      <p className="mt-2 text-gray-600">
        If you need more information, you can contact us at{' '}
        <a
          className="text-orange-600 underline underline-offset-2 hover:text-orange-700"
          target="_blank"
          href={'mailto:' + infoEmailAddress}
          rel="noreferrer"
        >
          {infoEmailAddress}
        </a>
        .
      </p>
    </div>
  )
}
