import * as React from 'react'

import CheckIcon from './CheckIcon'

interface Props {
  currentStepIndex: number
  steps: string[]
}

export default function Stepper(props: Props) {
  return (
    <nav className="text-sm">
      <ul className="flex flex-col space-y-4 pl-7">
        {props.steps.map((step, i) => {
          const isComplete = props.currentStepIndex > i
          const isCurrent = props.currentStepIndex === i
          return (
            <li
              aria-current={isCurrent ? 'step' : 'false'}
              className="relative"
              key={step}
            >
              {i > 0 && (
                <div
                  className={`${
                    isComplete ? 'bg-red-500' : 'bg-slate-300'
                  } absolute -left-5 -top-[18px] -z-10 h-5 w-px`}
                />
              )}
              <span className="absolute -ml-7">
                <CheckIcon isChecked={isComplete} />
              </span>{' '}
              <span
                className={
                  'whitespace-nowrap ' +
                  (isCurrent ? 'text-slate-900' : 'text-slate-500')
                }
              >
                {step}
              </span>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
