import { useParams } from 'react-router-dom'
import * as React from 'react'

import { ContractWizardState } from '../types'
import * as api from '../api'

import PaymentDetailsStep, {
  FormData as PaymentDetailsFormData,
} from '../components/PaymentDetailsStep'
import W8benStep, { FormData as W8benFormData } from '../components/W8benStep'
import PendingReviewStep from '../components/PendingReviewStep'
import SignContractStep from '../components/SignContractStep'
import Stepper from '../components/Stepper'
import Layout from '../components/Layout'

import LoadingPage from './LoadingPage'
import ErrorPage from './ErrorPage'

export default function OfferWizard() {
  const { id } = useParams<{ id: string }>()
  if (!id) throw new Error('A contract id is required')

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [state, setState] = React.useState<ContractWizardState | null>(null)
  const [error, setError] = React.useState<boolean>(false)

  React.useEffect(
    function loadContractWizardState() {
      setIsLoading(true)
      api.contracts
        .getContractStatus(id)
        .then((res: ContractWizardState) => setState(res))
        .catch((err) => setError(!!err.message))
        .finally(() => setIsLoading(false))
    },
    [id]
  )

  const handleContractSign = React.useCallback(
    async (data: { signature: string }) => {
      return api.contracts
        .signContract(id, data)
        .then((res: ContractWizardState) => setState(res))
    },
    [id]
  )

  const handleW8benSubmit = React.useCallback(
    async (data: W8benFormData) => {
      return api.contracts
        .generateW8ben(id, data)
        .then((res: ContractWizardState) => setState(res))
    },
    [id]
  )

  const handlePaymentDetailsSubmit = React.useCallback(
    async (data: PaymentDetailsFormData) => {
      return api.contracts
        .postPaymentDetails(id, data)
        .then((res: ContractWizardState) => setState(res))
    },
    [id]
  )

  if (isLoading) return <LoadingPage />
  if (error || !state) return <ErrorPage />

  const isSignatureStep =
    state.status === 'incomplete' && !state.contract.signature

  const isW8benStep =
    state.status === 'incomplete' && !isSignatureStep && !state.w8ben

  const isPaymentDetailsStep =
    state.status === 'incomplete' &&
    !isSignatureStep &&
    !isW8benStep &&
    state.paymentDetails === null

  const isPendingReview = state.status === 'pending-review'

  return (
    <Layout
      sidebar={
        <Stepper
          currentStepIndex={
            isPendingReview ? 4 : isPaymentDetailsStep ? 3 : isW8benStep ? 2 : 1
          }
          steps={[
            'Receive the Contract',
            'Sign the Contract',
            'Complete your W-8BEN',
            'Provide your payment details',
            'Wait for review',
          ]}
        />
      }
    >
      {isSignatureStep && (
        <SignContractStep
          contractUrl={state.contract.url}
          onSubmit={handleContractSign}
        />
      )}

      {isW8benStep && <W8benStep onSubmit={handleW8benSubmit} />}

      {isPaymentDetailsStep && (
        <PaymentDetailsStep onSubmit={handlePaymentDetailsSubmit} />
      )}

      {isPendingReview && <PendingReviewStep />}
    </Layout>
  )
}
