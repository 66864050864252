import { UseFormRegisterReturn } from 'react-hook-form'
import * as React from 'react'

type Props = {
  value: string
} & UseFormRegisterReturn &
  JSX.IntrinsicElements['input']

const RadioField = React.forwardRef((props: Props, ref) => {
  const { className, children, ...other } = props

  return (
    <div className={className}>
      <label className="inline-flex cursor-pointer items-center">
        <input
          className="text-red-500 accent-red-500 focus:ring-red-500  disabled:text-red-300"
          type="radio"
          {...other}
          ref={(e) => {
            if (e && typeof ref === 'function') ref(e)
          }}
        />
        <span className="ml-2 text-sm">{children}</span>
      </label>
    </div>
  )
})

RadioField.displayName = 'RadioField'

export default RadioField
