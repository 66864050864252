import { useFormContext } from 'react-hook-form'
import * as React from 'react'

import { PaymentDetails } from '../types'
import TextField from './TextField'

const required = 'This field is required'

export default function NonUsBankAccountForm() {
  const { register } = useFormContext<PaymentDetails>()

  return (
    <div className="max-w-2xl">
      <hr className="my-8" />
      <p className="text-slate-500">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <TextField
        className="mt-4 sm:mt-8"
        label="Name on the account"
        helpText="We must send payment only to accounts under your name"
        {...register('name', { required })}
      />
      <TextField
        className="mt-2"
        label="Your address"
        multiline
        rows={4}
        {...register('address', { required })}
      />
      <div className="gap-8  sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Account number"
          {...register('accountNumber', { required })}
        />
      </div>
      <div className="gap-8  sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Country of the account"
          {...register('accountCountry', { required })}
        />
        <TextField
          className="mt-2"
          label="Currency on the account"
          {...register('accountCurrency', { required })}
        />
      </div>
      <div className="gap-8  sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Bank name"
          {...register('bankName', { required })}
        />
        <TextField
          className="mt-2"
          label="Bank SWIFT code"
          {...register('bankSwift', { required })}
        />
      </div>
      <TextField
        className="mt-2"
        label="Bank address"
        multiline
        rows={4}
        {...register('bankAddress', { required })}
      />
    </div>
  )
}
