import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import * as React from 'react'

import DownloadFileLink from './DownloadFileLink'
import Spinner from './Spinner'

interface Props {
  onSubmit(data: { signature: string }): Promise<void>
  contractUrl: string
}

interface FormData {
  signature: string
}

export default function SignContractStep(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>()

  const onSubmit = (data: FormData) => {
    setError(null)
    setIsSubmitting(true)
    props
      .onSubmit(data)
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  const hasErrors = errors && !!errors.signature

  return (
    <div>
      <h1 className="mb-4 text-3xl">Independent Contractor Agreement</h1>
      <p className="max-w-2xl text-slate-500">
        Texto explicando qué es este documento. Loccaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <iframe
        className="mt-12 block w-full shadow-lg"
        style={{ height: 'calc(100vh - 550px)' }}
        src={props.contractUrl + '#navpanes=0&toolbar=0'}
        title="The offer letter PDF file"
      />
      <form
        className="mt-8 mb-8 items-start pr-4 md:flex"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mr-0 grow md:mr-8 lg:mr-28">
          <input
            className={`${
              hasErrors ? 'bg-red-50' : 'bg-slate-50'
            } block w-full border-0 border-b-2 border-gray-200 p-0.5 text-4xl focus:border-blue-500 focus:ring-0`}
            style={{ fontFamily: "'Dancing Script', cursive" }}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="false"
            type="text"
            autoFocus
            {...register('signature', { required: 'This field is required' })}
          />
          <label htmlFor="signature" className="text-sm text-slate-600">
            Enter your full name
            <span className="hidden lg:inline"> (Contractor Signature)</span>
          </label>
          <div className="text-sm text-red-600">
            <ErrorMessage errors={errors} name="signature" />
          </div>
          {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
        </div>
        <button
          className="shrink-1 text-md mt-6 block w-full whitespace-nowrap rounded-md bg-red-500 py-3 px-4 font-semibold text-white shadow ring-red-400 ring-offset-2 hover:bg-red-600 focus:ring-2 disabled:bg-red-300 md:mt-0 md:w-auto lg:px-6"
          disabled={isSubmitting}
          type="submit"
        >
          {isSubmitting && (
            <span className="relative top-0.5 mr-2 inline-block">
              <Spinner reverse size="small" />
            </span>
          )}
          {isSubmitting ? 'Signing...' : 'Agree & Sign'}
        </button>
      </form>

      {/**
       * TODO: maybe replace with a button than triggers a POST request, build
       * the PDF on the server, respond with the file and force download.
       */}
      <DownloadFileLink
        fileName="Southteams - Independent Contractor Agreement.pdf"
        url={props.contractUrl}
      >
        Download PDF
      </DownloadFileLink>
    </div>
  )
}
