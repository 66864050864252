import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import * as React from 'react'

import DownloadFileLink from './DownloadFileLink'
import RadioField from './RadioField'
import TextField from './TextField'
import Spinner from './Spinner'

export type FormData = {
  firstName: string
  lastName: string
  countryOfCitizenship: string
  residentialAddress: string
  country: string
  city: string
  dateOfBirth: string
  taxIdType: 'us' | 'non-us' | null
  taxId: string
  signature: string
  printName: string
  signedOnDate: string
}

interface Props {
  onSubmit(data: FormData): Promise<void>
}

const required = 'This field is required'

const defaultValues: FormData = {
  firstName: '',
  lastName: '',
  countryOfCitizenship: '',
  residentialAddress: '',
  country: '',
  city: '',
  dateOfBirth: '',
  taxIdType: null,
  taxId: '',
  signature: '',
  printName: '',
  signedOnDate: new Date().toISOString().substr(0, 10),
}

export default function W8benStep(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({ defaultValues })

  const onSubmit = (data: FormData) => {
    setError(null)
    setIsSubmitting(true)
    props
      .onSubmit(data)
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <div>
      <h1 className="mb-4 text-3xl">Complete your W-8BEN</h1>
      <p className="max-w-2xl text-slate-500">
        We will collect the necessary information to generate a W-BEN which
        justifies you not being US citizen and therefore not paying US taxes.
      </p>

      <form className="mt-10 max-w-2xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid-cols-2 gap-8 sm:grid lg:grid-cols-3 ">
          <TextField
            label="First name"
            errors={errors}
            autoFocus
            {...register('firstName', { required })}
          />
          <TextField
            label="Last name"
            errors={errors}
            {...register('lastName', { required })}
          />
          <TextField
            label="Country of citizenship"
            errors={errors}
            {...register('countryOfCitizenship', { required })}
          />
        </div>
        <TextField
          className="mt-2"
          label={
            <>
              Permanent residential address{' '}
              <small className="block text-slate-500 md:inline">
                (street, apt. or suite number, not P.O. box or zip code)
              </small>
            </>
          }
          errors={errors}
          {...register('residentialAddress', { required })}
        />
        <TextField
          className="mt-2 max-w-none md:max-w-xs"
          label="Country"
          errors={errors}
          {...register('country', { required })}
        />
        <TextField
          className="mt-2"
          label={
            <>
              City or town, state or province{' '}
              <small className="block text-slate-500 md:inline">
                (include postal code where appropriate)
              </small>
            </>
          }
          errors={errors}
          {...register('city', { required })}
        />
        <TextField
          className="mt-2 max-w-none md:max-w-xs"
          label="Date of birth"
          errors={errors}
          type="date"
          {...register('dateOfBirth', { required })}
        />

        <div className="mt-2">
          <label className="text-sm text-slate-600">
            Tax identification number
          </label>
          <div className="mt-4 flex flex-wrap items-baseline gap-2 sm:gap-12">
            <RadioField
              value="us"
              {...register('taxIdType', {
                required: 'Choose a tax identification number type',
              })}
            >
              US taxpayer identification number
            </RadioField>
            <RadioField
              value="non-us"
              {...register('taxIdType', {
                required: 'Choose a tax identification number type',
              })}
            >
              Foreign taxpayer identification number
            </RadioField>
          </div>
          <div className="mt-2 text-sm text-red-600">
            <ErrorMessage errors={errors} name="taxIdType" />
          </div>
          <TextField
            className="mt-4 max-w-none md:max-w-xs"
            label="Tax identification number"
            labelClassName="visually-hidden"
            errors={errors}
            {...register('taxId', { required })}
          />
        </div>

        <TextField
          className="mt-2"
          label="Signature of beneficial owner"
          style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: '1.875rem',
          }}
          errors={errors}
          {...register('signature', { required })}
        />
        <TextField
          className="mt-2"
          label="Print name of beneficial owner"
          errors={errors}
          {...register('printName', { required })}
        />
        <TextField
          className="mt-2 max-w-none md:max-w-xs"
          label="Signed on"
          errors={errors}
          type="date"
          {...register('signedOnDate', { required })}
        />

        {error && <div className="mt-4 text-sm text-red-600">{error}</div>}

        <button
          className="text-md mt-6 block w-full min-w-[140px] whitespace-nowrap rounded-md bg-red-500 py-2 px-3 font-semibold text-white shadow ring-red-400 ring-offset-2 hover:bg-red-600 focus:ring-2 disabled:bg-red-300 md:w-auto lg:py-3 lg:px-6"
          disabled={isSubmitting}
          type="submit"
        >
          {isSubmitting && (
            <span className="relative top-0.5 mr-2 inline-block">
              <Spinner reverse size="small" />
            </span>
          )}
          {isSubmitting ? 'Generating W-8BEN...' : 'Next'}
        </button>
      </form>

      <div className="my-12 flex flex-col gap-6 md:flex-row md:gap-12">
        <DownloadFileLink url="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf">
          Official W-8BEN Form
        </DownloadFileLink>
        <DownloadFileLink url="https://www.irs.gov/pub/irs-pdf/iw8ben.pdf">
          Official W-8BEN Instructions
        </DownloadFileLink>
      </div>
    </div>
  )
}
