import { GENERIC_ERROR_MESSAGE } from './common'
import { OfferWizardState } from '../types'
import { apiBaseUrl } from '../config'

export async function getOfferStatus(id: OfferWizardState['id']) {
  return fetch(apiBaseUrl + '/offer/' + id).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function signOfferLetter(
  id: OfferWizardState['id'],
  body: { signature: string }
) {
  return fetch(apiBaseUrl + '/offer/' + id + '/sign', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function uploadDocs(id: OfferWizardState['id'], body: FormData) {
  return fetch(apiBaseUrl + '/offer/' + id + '/docs', {
    method: 'POST',
    body,
  }).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}
