import * as React from 'react'

export default function NotFoundPage() {
  return (
    <div className="flex h-screen items-center">
      <main className="container mx-auto mb-32 p-4 text-center">
        <h1 className="text-4xl text-orange-600">404 - Not Found</h1>
        <p className="mt-4 text-gray-600">This route does not exist</p>
      </main>
    </div>
  )
}
