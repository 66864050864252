import { useParams } from 'react-router-dom'
import * as React from 'react'

import { OfferWizardState } from '../types'
import * as api from '../api'

import SignOfferLetterStep from '../components/SignOfferLetterStep'
import PendingReviewStep from '../components/PendingReviewStep'
import UploadDocsStep from '../components/UploadDocsStep'
import Stepper from '../components/Stepper'
import Layout from '../components/Layout'

import LoadingPage from './LoadingPage'
import ErrorPage from './ErrorPage'

export default function OfferWizardPage() {
  const { id } = useParams<{ id: string }>()
  if (!id) throw new Error('An offer id is required')

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [state, setState] = React.useState<OfferWizardState | null>(null)
  const [error, setError] = React.useState<boolean>(false)

  React.useEffect(
    function loadOfferWizardState() {
      setIsLoading(true)
      api.offers
        .getOfferStatus(id)
        .then((res: OfferWizardState) => setState(res))
        .catch((err) => setError(!!err.message))
        .finally(() => setIsLoading(false))
    },
    [id]
  )

  const handleOfferLetterSign = React.useCallback(
    async (body: { signature: string }) => {
      return api.offers
        .signOfferLetter(id, body)
        .then((res: OfferWizardState) => setState(res))
    },
    [id]
  )

  const handleDocsUpload = React.useCallback(
    async (body: FormData) => {
      return api.offers
        .uploadDocs(id, body)
        .then((res: OfferWizardState) => setState(res))
    },
    [id]
  )

  if (isLoading) return <LoadingPage />
  if (error || !state) return <ErrorPage />

  const isSignatureStep =
    state.status === 'incomplete' && !state.letter.signature

  const isUploadDocsStep =
    state.status === 'incomplete' &&
    !!state.letter.signature &&
    state.docs === null

  const isPendingReview = state.status === 'pending-review'

  return (
    <Layout
      sidebar={
        <Stepper
          currentStepIndex={isPendingReview ? 3 : isUploadDocsStep ? 2 : 1}
          steps={[
            'Receive Offer Letter',
            'Sign the Offer Letter',
            'Upload your ID documents',
            'Wait for documents review',
          ]}
        />
      }
    >
      {isSignatureStep && (
        <SignOfferLetterStep
          offerLetterUrl={state.letter.url}
          onSubmit={handleOfferLetterSign}
        />
      )}

      {isUploadDocsStep && <UploadDocsStep onSubmit={handleDocsUpload} />}

      {isPendingReview && <PendingReviewStep />}
    </Layout>
  )
}
