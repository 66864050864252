import { rest } from 'msw'

import { ContractWizardState } from '../types'
import { apiBaseUrl } from '../config'

const getContractStatusHandler = rest.get<
  {},
  { id: string },
  ContractWizardState
>(apiBaseUrl + '/contract/:id', (req, res, ctx) => {
  if (req.params.id === 'expired') return res(ctx.status(404), ctx.delay(200))
  return res(
    ctx.status(200),
    ctx.json({
      id: req.params.id,
      contract: {
        signature:
          {
            signed: 'John Doe',
            'pending-review': 'Mary Poppins',
            'payment-details': 'Jane Doe',
          }[req.params.id] || null,
        url: process.env.PUBLIC_URL + '/offer-letter.pdf',
      },
      w8ben: req.params.id === 'payment-details',
      paymentDetails: null,
      status:
        {
          'pending-review': 'pending-review' as ContractWizardState['status'],
        }[req.params.id] || 'incomplete',
    }),
    ctx.delay(200)
  )
})

const signContractHandler = rest.post<
  { signature: string },
  { id: string },
  ContractWizardState
>(apiBaseUrl + '/contract/:id/sign', (req, res, ctx) => {
  // sign as "error" to mock an error response
  if (req.body.signature === 'error') {
    return res(ctx.status(400), ctx.delay(200))
  }
  return res(
    ctx.status(200),
    ctx.json({
      id: req.params.id,
      contract: {
        signature: req.body.signature,
        url: process.env.PUBLIC_URL + '/offer-letter.pdf',
      },
      w8ben: false,
      paymentDetails: null,
      status: 'incomplete',
    }),
    ctx.delay(600)
  )
})

const generateW8benHandler = rest.post<
  { signature: string },
  { id: string },
  ContractWizardState
>(apiBaseUrl + '/contract/:id/w8ben', (req, res, ctx) => {
  // sign as "error" to mock an error response
  if (req.body.signature === 'error') {
    return res(ctx.status(400), ctx.delay(200))
  }
  return res(
    ctx.status(200),
    ctx.json({
      id: req.params.id,
      contract: {
        signature: req.body.signature,
        url: process.env.PUBLIC_URL + '/offer-letter.pdf',
      },
      w8ben: true,
      paymentDetails: null,
      status: 'incomplete',
    }),
    ctx.delay(600)
  )
})

const postPaymentDetailsHandler = rest.post<
  ContractWizardState['paymentDetails'],
  { id: string },
  ContractWizardState
>(apiBaseUrl + '/contract/:id/payment', (req, res, ctx) => {
  // use name "error" to mock an error response
  if (req.body?.type === 'us-account' && req.body.name === 'error') {
    return res(ctx.status(400), ctx.delay(200))
  }
  return res(
    ctx.status(200),
    ctx.json({
      id: req.params.id,
      contract: {
        signature: 'foo',
        url: process.env.PUBLIC_URL + '/offer-letter.pdf',
      },
      w8ben: true,
      paymentDetails: req.body,
      status: 'pending-review',
    }),
    ctx.delay(600)
  )
})

export const handlers = [
  getContractStatusHandler,
  signContractHandler,
  generateW8benHandler,
  postPaymentDetailsHandler,
]
