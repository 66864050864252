import { Routes, Route } from 'react-router-dom'
import * as React from 'react'

import ContractWizardPage from './pages/ContractWizardPage'
import OfferWizardPage from './pages/OfferWizardPage'
import NotFoundPage from './pages/NotFoundPage'
import MocksIndex from './pages/MocksIndex'

import { useMockedApi } from './config'

function App() {
  return (
    <Routes>
      {useMockedApi && <Route path="/" element={<MocksIndex />} />}
      <Route path="/contract/:id" element={<ContractWizardPage />} />
      <Route path="/offer/:id" element={<OfferWizardPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default App
