import { GENERIC_ERROR_MESSAGE } from './common'
import { ContractWizardState } from '../types'
import { apiBaseUrl } from '../config'

export async function getContractStatus(id: ContractWizardState['id']) {
  return fetch(apiBaseUrl + '/contract/' + id).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function signContract(
  id: ContractWizardState['id'],
  data: { signature: string }
) {
  return fetch(apiBaseUrl + '/contract/' + id + '/sign', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function generateW8ben(
  id: ContractWizardState['id'],
  data: { signature: string }
) {
  return fetch(apiBaseUrl + '/contract/' + id + '/w8ben', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function postPaymentDetails(
  id: ContractWizardState['id'],
  data: ContractWizardState['paymentDetails']
) {
  return fetch(apiBaseUrl + '/contract/' + id + '/payment', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}
