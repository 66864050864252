import * as React from 'react'

interface Props {
  children: React.ReactNode
  sidebar?: React.ReactNode
}

export default function Layout({ children, sidebar }: Props) {
  return (
    <div className="mx-auto px-4 md:flex lg:container">
      <div className="mt-8 shrink-0 md:w-1/4 xl:w-1/5">
        <div className="mb-12 max-w-sm md:mb-16">
          <img
            width="234"
            height="32"
            className="block w-3/4 max-w-[234px]"
            alt="Southteams"
            // TODO: replace with SVG version
            src={process.env.PUBLIC_URL + '/images/logo-navbar.png'}
          />
        </div>
        {sidebar}
      </div>
      <main className="my-12 grow md:my-28 md:ml-16">{children}</main>
    </div>
  )
}
