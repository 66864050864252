import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import * as React from 'react'

import { friendlyFileSize } from '../utils'
import { maxUploadSize } from '../config'
import ImageUploader from './ImageUploader'
import Spinner from './Spinner'

interface Props {
  onSubmit(formData: globalThis.FormData): Promise<void>
}

type FormData =
  | {
      idType: 'localId'
      frontImg: FileList
      backImg: FileList
    }
  | { idType: 'passport'; img: FileList }

export default function UploadDocsStep(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormData>({ defaultValues: { idType: 'localId' } })

  const onSubmit = (data: FormData) => {
    setError(null)
    setIsSubmitting(true)
    const formData = new FormData()
    formData.append('type', data.idType)
    if (data.idType === 'localId' && data.frontImg[0] && data.backImg[0]) {
      formData.append('frontImg', data.frontImg[0])
      formData.append('backImg', data.backImg[0])
    }
    if (data.idType === 'passport' && data.img[0]) {
      formData.append('frontImg', data.img[0])
    }
    props
      .onSubmit(formData)
      .catch((err) => setError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <div>
      <h1 className="mb-4 text-3xl">Upload your ID documents</h1>
      <p className="max-w-2xl text-slate-500">
        Texto explicando cuáles son los documentos válidos, etc. Loccaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
        id est laborum.
      </p>

      <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <label className="mb-2 block font-bold">Type:</label>
        <div className="flex items-baseline space-x-24">
          <div>
            <label className="inline-flex cursor-pointer items-center">
              <input
                className="text-red-500 accent-red-500 focus:ring-red-500  disabled:text-red-300"
                type="radio"
                value="localId"
                id="localId"
                disabled={isSubmitting}
                {...register('idType', { required: true })}
              />
              <span className="ml-2">Local ID</span>
            </label>
          </div>
          <div>
            <label className="inline-flex cursor-pointer items-center">
              <input
                className="text-red-500 accent-red-500 focus:ring-red-500 disabled:text-red-300"
                type="radio"
                value="passport"
                id="passport"
                disabled={isSubmitting}
                {...register('idType')}
              />
              <span className="ml-2">Passport</span>
            </label>
          </div>
        </div>

        <div className={watch('idType') !== 'localId' ? 'hidden' : ''}>
          <div className="mt-8 grid gap-4 sm:grid-cols-2">
            <div>
              <div className="mb-4 text-center text-xs font-bold uppercase">
                Front
              </div>
              <ImageUploader
                disabledRemove={isSubmitting}
                {...register('frontImg', {
                  required:
                    watch('idType') === 'localId'
                      ? 'A front picture is required'
                      : false,
                  validate: (filelist) => {
                    if (watch('idType') !== 'localId') return true
                    const file = filelist.item(0)
                    return file && file.size > maxUploadSize
                      ? `File size must be less than ${friendlyFileSize(
                          maxUploadSize
                        )}`
                      : true
                  },
                })}
              />
              <div className="mt-4 text-sm text-red-600">
                <ErrorMessage errors={errors} name="frontImg" />
              </div>
            </div>
            <div>
              <div className="mb-4 text-center text-xs font-bold uppercase">
                Back
              </div>
              <ImageUploader
                disabledRemove={isSubmitting}
                {...register('backImg', {
                  required:
                    watch('idType') === 'localId'
                      ? 'A back picture is required'
                      : false,
                  validate: (filelist) => {
                    if (watch('idType') !== 'localId') return true
                    const file = filelist.item(0)
                    return file && file.size > maxUploadSize
                      ? `File size must be less than ${friendlyFileSize(
                          maxUploadSize
                        )}`
                      : true
                  },
                })}
              />
              <div className="mt-4 text-sm text-red-600">
                <ErrorMessage errors={errors} name="backImg" />
              </div>
            </div>
          </div>
        </div>

        <div className={watch('idType') !== 'passport' ? 'hidden' : ''}>
          <div className="mt-8 grid gap-4 sm:grid-cols-2">
            <div>
              <div className="mb-4 text-center text-xs font-bold uppercase">
                Front
              </div>
              <ImageUploader
                disabledRemove={isSubmitting}
                {...register('img', {
                  required:
                    watch('idType') === 'passport'
                      ? 'A front picture is required'
                      : false,
                  validate: (filelist) => {
                    if (watch('idType') !== 'passport') return true
                    const file = filelist.item(0)
                    return file && file.size > maxUploadSize
                      ? `File size must be less than ${friendlyFileSize(
                          maxUploadSize
                        )}`
                      : true
                  },
                })}
              />
            </div>
          </div>
          <div className="mt-4 text-sm text-red-600">
            <ErrorMessage errors={errors} name="img" />
          </div>
        </div>

        {error && <div className="mt-4 text-sm text-red-600">{error}</div>}

        <button
          className="text-md mt-6 w-full max-w-none rounded-md bg-red-500 py-3 px-8 font-semibold text-white shadow ring-red-400 ring-offset-2 hover:bg-red-600 focus:ring-2 disabled:bg-red-300 md:mt-12 md:max-w-[180px]"
          disabled={isSubmitting}
          type="submit"
        >
          {isSubmitting && (
            <span className="relative top-0.5 mr-2 inline-block">
              <Spinner reverse size="small" />
            </span>
          )}
          {isSubmitting ? 'Sending...' : 'Send'}
        </button>
      </form>
    </div>
  )
}
