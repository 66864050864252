import * as React from 'react'

type Props = JSX.IntrinsicElements['input'] & {
  disabledRemove?: boolean
}

const ImageUploader = React.forwardRef(
  ({ disabledRemove, ...otherProps }: Props, ref) => {
    const fileInputField = React.useRef<HTMLInputElement | null>(null)
    const [file, setFile] = React.useState<null | File>(null)

    const [isDropTarget, setIsDropTarget] = React.useState(false)

    return (
      <div
        className={'relative'}
        onDragEnter={() => setIsDropTarget(true)}
        onDragOver={() => setIsDropTarget(true)}
        onDragLeave={() => setIsDropTarget(false)}
        onDragEnd={() => setIsDropTarget(false)}
        onDrop={() => setIsDropTarget(false)}
      >
        <input
          className={`${
            isDropTarget ? 'z-0' : '-z-10'
          } absolute top-0 bottom-0 left-0 right-0 border-none opacity-0 focus:outline-none`}
          type="file"
          title=""
          accept="image/*"
          {...otherProps}
          onChange={(e) => {
            const { files } = e.target
            if (files?.length && files[0]) setFile(files[0])
            if (otherProps.onChange) otherProps.onChange(e)
          }}
          ref={(e) => {
            if (e && typeof ref === 'function') ref(e)
            fileInputField.current = e
          }}
        />

        {file ? (
          <div>
            <img
              className="block aspect-video w-full rounded-md bg-slate-200 object-contain shadow-lg"
              src={URL.createObjectURL(file)}
              alt=""
            />
            {!isDropTarget && (
              <button
                className="absolute top-2 right-2 z-10 block rounded-sm bg-white py-1 px-2 text-xs font-semibold text-slate-600 shadow outline-none ring-offset-2 hover:bg-slate-100 disabled:bg-gray-300"
                disabled={disabledRemove}
                type="button"
                onClick={() => {
                  if (fileInputField.current) {
                    fileInputField.current.value = ''
                  }
                  setFile(null)
                }}
              >
                Remove
              </button>
            )}
          </div>
        ) : (
          <div className="flex aspect-video items-center justify-center rounded-md border-2 border-dashed bg-slate-50 p-2">
            <div className="pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="mx-auto mb-4 block text-slate-400"
                viewBox="0 0 16 16"
                transform="scale(-1, 1)"
              >
                <path
                  fillRule="evenodd"
                  d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5ZM10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3ZM13 8h-2V6h2v2Z"
                />
              </svg>
              <div className="text-center text-sm">Drag and drop file here</div>
              <div className="my-3 text-center text-sm">or</div>
              <button
                className="mx-auto block rounded-md bg-white py-2 px-4 text-sm font-semibold text-slate-600 shadow outline-none ring-slate-200 ring-offset-2 hover:bg-slate-100 focus:ring-2"
                type="button"
                onClick={() => {
                  if (fileInputField.current) fileInputField.current.click()
                }}
              >
                Browse for file
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
)

ImageUploader.displayName = 'ImageUploader'

export default ImageUploader
