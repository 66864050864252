export const infoEmailAddress =
  process.env.REACT_APP_INFO_EMAIL_ADDRESS || 'info@southteams.com'

export const apiBaseUrl =
  process.env.REACT_APP_ONBOARDING_API || 'https://example.com/api'
export const useMockedApi = process.env.REACT_APP_USE_MOCKED_API === 'true'

const parsedMaxUploadSize = parseInt(
  process.env.REACT_APP_MAX_UPLOAD_SIZE || '',
  10
)

export const maxUploadSize = Number.isNaN(parsedMaxUploadSize)
  ? 10 * 1024 // Default: 10 KB
  : parsedMaxUploadSize
