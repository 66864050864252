import * as React from 'react'

import Spinner from '../components/Spinner'

export default function LoadingPage() {
  return (
    <div className="flex h-screen w-screen items-center justify-center pb-32">
      <Spinner />
    </div>
  )
}
