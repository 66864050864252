import { rest } from 'msw'

import { OfferWizardState } from '../types'
import { apiBaseUrl } from '../config'

const getOfferStatusHandler = rest.get<{}, { id: string }, OfferWizardState>(
  apiBaseUrl + '/offer/:id',
  (req, res, ctx) => {
    if (req.params.id === 'expired') return res(ctx.status(404), ctx.delay(200))
    return res(
      ctx.status(200),
      ctx.json({
        id: req.params.id,
        letter: {
          signature:
            { signed: 'John Doe', 'pending-review': 'Mary Poppins' }[
              req.params.id
            ] || null,
          url: process.env.PUBLIC_URL + '/offer-letter.pdf',
        },
        docs:
          { 'pending-review': { type: 'passport' as const, frontUrl: '' } }[
            req.params.id
          ] || null,
        status:
          { 'pending-review': 'pending-review' as const }[req.params.id] ||
          'incomplete',
      }),
      ctx.delay(200)
    )
  }
)

const signOfferLetterHandler = rest.post<
  { signature: string },
  { id: string },
  OfferWizardState
>(apiBaseUrl + '/offer/:id/sign', (req, res, ctx) => {
  // sign as "error" to mock an error response
  if (req.body.signature === 'error') {
    return res(ctx.status(400), ctx.delay(200))
  }
  return res(
    ctx.status(200),
    ctx.json({
      id: req.params.id,
      letter: {
        signature: req.body.signature,
        url: process.env.PUBLIC_URL + '/offer-letter.pdf',
      },
      docs: null,
      status: 'incomplete',
    }),
    ctx.delay(600)
  )
})

const uploadDocsHandler = rest.post<
  | { type: 'localId'; frontImg: File; backImg: File }
  | { type: 'passport'; frontImg: File },
  { id: string },
  OfferWizardState
>(apiBaseUrl + '/offer/:id/docs', (req, res, ctx) => {
  // send an image named "error" to mock an error response
  if (req.body.frontImg.name.includes('error')) {
    return res(ctx.status(400), ctx.delay(200))
  }
  return res(
    ctx.status(200),
    ctx.json({
      id: req.params.id,
      letter: {
        signature: 'John Doe',
        url: process.env.PUBLIC_URL + '/offer-letter.pdf',
      },
      docs: {
        type: req.body.type,
        frontUrl: URL.createObjectURL(req.body.frontImg),
        backUrl:
          req.body.type === 'localId'
            ? URL.createObjectURL(req.body.backImg)
            : null,
      },
      status: 'pending-review',
    }),
    ctx.delay(2000)
  )
})

export const handlers = [
  getOfferStatusHandler,
  signOfferLetterHandler,
  uploadDocsHandler,
]
