import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import * as msw from 'msw'
import React from 'react'

import './index.css'
import { apiBaseUrl, useMockedApi } from './config'
import reportWebVitals from './reportWebVitals'
import App from './App'

if (useMockedApi) {
  const { worker }: { worker: msw.SetupWorkerApi } = require('./mocks/browser')
  worker.start({
    onUnhandledRequest(req, print) {
      // omit msw warnings for non-api requests
      if (!req.url.href.startsWith(apiBaseUrl)) return
      print.warning()
    },
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
