import { useForm, FormProvider } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import * as React from 'react'

import NonUsBankAccountForm from './NonUsBankAccountForm'
import UsaBankAccountForm from './UsaBankAccountForm'
import PayoneerTutorial from './PayoneerTutorial'
import Spinner from './Spinner'

import { PaymentDetails } from '../types'

type OptionProps = {
  children: React.ReactNode
} & JSX.IntrinsicElements['input']

const Option = React.forwardRef<HTMLInputElement, OptionProps>(
  ({ children, ...other }, ref) => {
    return (
      <label className="w-full cursor-pointer">
        <input
          className="peer absolute text-red-500 accent-red-500 opacity-0 focus:ring-red-500  disabled:text-red-300"
          type="radio"
          id="payoneer"
          {...other}
          ref={ref}
        />
        <span className="flex h-full grow items-center justify-center rounded-md border-2 bg-slate-100 py-4 px-2 text-center text-base peer-checked:border-slate-400 peer-checked:bg-slate-300 peer-disabled:opacity-50 md:text-xs xl:text-base">
          {children}
        </span>
      </label>
    )
  }
)

Option.displayName = 'Option'

export type FormData = PaymentDetails | null

interface Props {
  onSubmit(data: FormData): Promise<void>
}

export default function PaymentDetailsStep(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isSkipping, setIsSkipping] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const methods = useForm<PaymentDetails>({
    shouldUnregister: true,
    defaultValues: {},
  })

  const onSubmit = (data: Parameters<Props['onSubmit']>[0]) => {
    setError(null)
    if (data === null) {
      setIsSkipping(true)
    } else {
      setIsSubmitting(true)
    }
    props
      .onSubmit(data)
      .catch((err) => setError(err.message))
      .finally(() => {
        setIsSubmitting(false)
        setIsSkipping(false)
      })
  }

  return (
    <div>
      <h1 className="mb-4 text-3xl">Payment details</h1>

      <div className="flex flex-col items-baseline justify-between gap-2 lg:flex-row lg:gap-16">
        <p className="max-w-2xl text-slate-500">
          Choose how you would like to receive your payments. You will be able
          to change these options anytime you want!
        </p>

        <button
          onClick={() => onSubmit(null)}
          type="button"
          className="text-md -ml-2 whitespace-nowrap rounded-md py-1 px-2 text-orange-600 ring-red-400 hover:text-orange-700 focus-visible:ring-2 disabled:text-red-300 lg:ml-0"
          disabled={isSkipping || isSubmitting}
        >
          {isSkipping && (
            <span className="relative top-0.5 mr-2 inline-block">
              <Spinner size="small" />
            </span>
          )}
          <span className="underline underline-offset-2">
            I&lsquo;ll do this later
          </span>
        </button>
      </div>
      <FormProvider {...methods}>
        <form className="my-10" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3">
            <Option
              value="payoneer"
              disabled={isSubmitting || isSkipping}
              {...methods.register('type', {
                required: 'Choose a payment method',
              })}
            >
              Payoneer
            </Option>
            <Option
              value="us-account"
              disabled={isSubmitting || isSkipping}
              {...methods.register('type', {
                required: 'Choose a payment method',
              })}
            >
              USA bank account
            </Option>
            <Option
              value="non-us-account"
              disabled={isSubmitting || isSkipping}
              {...methods.register('type', {
                required: 'Choose a payment method',
              })}
            >
              International bank account
            </Option>
          </div>

          <div className="mt-2 text-sm text-red-600">
            <ErrorMessage errors={methods.formState.errors} name="type" />
          </div>

          {methods.watch('type') === 'payoneer' && <PayoneerTutorial />}

          {methods.watch('type') === 'us-account' && <UsaBankAccountForm />}

          {methods.watch('type') === 'non-us-account' && (
            <NonUsBankAccountForm />
          )}

          {error && <div className="mt-4 text-sm text-red-600">{error}</div>}

          {methods.watch('type') && (
            <button
              className="text-md mt-8 block  w-full min-w-[180px] rounded-md bg-red-500 py-3 px-8 font-semibold text-white shadow ring-red-400 ring-offset-2 hover:bg-red-600 focus:ring-2 disabled:bg-red-300 md:w-auto"
              disabled={isSkipping || isSubmitting}
              type="submit"
            >
              {isSubmitting && (
                <span className="relative top-0.5 mr-2 inline-block">
                  <Spinner reverse size="small" />
                </span>
              )}
              {isSubmitting
                ? 'Saving...'
                : methods.watch('type') === 'payoneer'
                ? 'Use Payoneer'
                : 'Save'}
            </button>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
