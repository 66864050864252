export function round(value: number, precision: number) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export function friendlyFileSize(number: number): string {
  if (number < 1024) {
    return number + ' bytes'
  } else if (number >= 1024 && number < 1048576) {
    return round(number / 1024, 1) + ' KB'
  }
  return round(number / 1048576, 1) + ' MB'
}
