import * as React from 'react'

export default function PayoneerForm() {
  return (
    <div>
      <hr className="my-8" />
      <p className="max-w-2xl text-slate-500">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <p className="mt-8 rounded-xl bg-slate-100 py-24 text-center italic text-slate-500">
        How to link your Payoneer account tutorial
      </p>
    </div>
  )
}
