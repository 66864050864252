import * as React from 'react'

import { infoEmailAddress } from '../config'

export default function ErrorPage() {
  return (
    <div className="flex h-screen items-center">
      <main className="container mx-auto mb-32 p-4 text-center">
        <h1 className="text-4xl text-orange-600">Invalid invite</h1>
        <p className="mx-auto mt-4 max-w-xl text-gray-600">
          This invite is invalid or expired. If you think this is an error,
          contact us at{' '}
          <a
            className="text-orange-600 underline underline-offset-2 hover:text-orange-700"
            target="_blank"
            href={'mailto:' + infoEmailAddress}
            rel="noreferrer"
          >
            {infoEmailAddress}
          </a>
        </p>
      </main>
    </div>
  )
}
